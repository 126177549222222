<template>
    <div class="home">
    <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="汇款地区类型">
            <el-select style="width:200px;" @change="onSubmit('cha')"  v-model="status" placeholder="境内">
                  <el-option
                    label="境内"
                    :value="1">
                  </el-option>
                  <el-option
                    label="境外"
                    :value="2">
                  </el-option>
            </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="请输入关键字"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="left">
        <el-button  @click="add()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
      </div>
      <div class="pt20"></div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="200"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="name"
          width="200"
          label="选项名称">
        </el-table-column>
        <el-table-column
          prop="type"
          label="汇款地区">
          <template  slot-scope="scope">
            {{scope.row.type == 1?'境内':'境外'}}
         </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="操作时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate}}
         </template>
        </el-table-column>
        <el-table-column
          prop="operator_name"
          label="操作人员">
        </el-table-column>
        <el-table-column
          label="操作">
          <template  slot-scope="scope">
            <el-button @click='bianji(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            <el-button @click='dd(scope.row.id)' size="mini" type="info" plain>删除</el-button>
         </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <el-dialog
        title="编辑银行"
        :visible.sync="eddbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="registerdata" :model="registerdata" label-width="120px">
            <el-form-item label="中文名称" prop="name">
              <el-input type="textarea" v-model="registerdata.name" placeholder="请输入中文名称" maxlength="100" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="en_name">
              <el-input type="textarea" v-model="registerdata.en_name" placeholder="请输入英文名称" maxlength="100" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="registerdata.type" required>
                <el-radio :label="1">境内</el-radio>
                <el-radio :label="2">境外</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input v-model.number="registerdata.sort" placeholder="请输入排序" clearable required></el-input>
            </el-form-item>
            <el-form-item label="是否显示说明" prop="has_explain">
              <el-checkbox v-model="registerdata.has_explain" :label="1" required>是</el-checkbox>
            </el-form-item>
            <el-form-item label="说明内容" prop="explain">
              <el-input v-model="registerdata.explain" placeholder="请输入说明内容" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="说明内容(英文)" prop="en_explain">
              <el-input v-model="registerdata.en_explain" placeholder="请输入说明内容(英文)" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitregisterdata">保存</el-button>
              <el-button @click="eddbankshow = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="新增银行"
        :visible.sync="addbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="中文名称" prop="name">
              <el-input type="textarea"  v-model="form.name" placeholder="请输入中文名称" maxlength="100" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="en_name">
              <el-input type="textarea" v-model="form.en_name" placeholder="请输入英文名称" maxlength="100" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="form.type" required>
                <el-radio label="1">境内</el-radio>
                <el-radio label="2">境外</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input v-model.number="form.sort" placeholder="请输入排序" clearable required></el-input>
            </el-form-item>
            <el-form-item label="是否显示说明" prop="has_explain">
              <el-checkbox v-model="form.has_explain" label="1" required>是</el-checkbox>
            </el-form-item>
            <el-form-item label="说明内容" prop="explain">
              <el-input v-model="form.explain" placeholder="请输入说明内容" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="说明内容(英文)" prop="en_explain">
              <el-input v-model="form.en_explain" placeholder="请输入说明内容(英文)" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  data(){
    return {
      form: {
        name: '',
        en_name: '',
        type: '',
        sort: '',
        has_explain: false,
        explain: '',
        en_explain: ''
      },
      registerdata:{
        name: '',
        en_name: '',
        type: '',
        sort: '',
        has_explain: false,
        explain: '',
        en_explain: ''
      },
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      addbankshow:false,
      eddbankshow:false,
      tableData: [],
      payChannel:[],//支付渠道
      formInline: {
         page:1,//页码 0不分页
         search_data:'',//检索内容
         type:0,//类型 1境内2境外
      },
      status:1,
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
         type:1,//类型 1境内2境外
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      bianji(bank_item_id){
        let _this = this;
        let data = {
          bank_item_id:bank_item_id
        }
        axios.get(config.bank_item_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.registerdata = response.data.data;
              _this.registerdata.has_explain = _this.registerdata.has_explain == 1 ? true : false;
              _this.eddbankshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      dd(bank_item_id){
        let _this = this;
        this.$confirm('此操作将删除列, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.delete(config.delete_bank_item,{data:{bank_item_id:bank_item_id}})
          .then(response => {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.onSubmit('CHA');
            }else{
                _this.$message.error(response.data.message);
              }
          })
          .catch(error => {
            _this.$message.error(error);
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
       
      },
      submitForm() {// 手动编写非空验证代码
        let _this = this;
        if (!this.form.name) {
          _this.$message.error('请输入中文名称');
          return false;
        }
        if (!this.form.en_name) {
          _this.$message.error('请输入英文名称');
          return false;
        }
        if (!this.form.type) {
          _this.$message.error('请选择类型');
          return false;
        }
        if (!this.form.sort) {
          _this.$message.error('请输入排序');
          return false;
        }
        this.form.has_explain = this.form.has_explain ? 1 : 0;
        this.$refs.form.validate(valid => {
          if (valid) {
            axios.put(config.add_bank_item,_this.form)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.addbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitregisterdata(){
        let _this = this;
        if (!this.registerdata.name || !this.registerdata.en_name || !this.registerdata.type || !this.registerdata.sort) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        this.registerdata.has_explain = this.registerdata.has_explain ? 1 : 0;
        this.registerdata.bank_item_id = this.registerdata.id;
        this.$refs.registerdata.validate(valid => {
          if (valid) {
            axios.post(config.edit_bank_item,_this.registerdata)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.eddbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });

      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      add(){
        this.addbankshow = true;
        this.$refs.form.resetFields();
      },
      exportlist(){

      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.type = this.status == '' ? 1 : this.status;
        axios.get(config.bank_item_list,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.bank_item_list,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>